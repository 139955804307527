import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Accordion, Card, Button } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Frequently Asked Questions"
      description="Here are the answers to the most frequently asked questions about JKA Design services. See how we can make your dream house a reality.
			"
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h1>
            <strong>Answers to JKA Design Frequently Asked Questions</strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <p>
              If you haven’t worked with an architect before, these answers
              should help with your most pressing questions.
            </p>
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Why should I hire an architect and what will they do for my
                    project?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                      Architects are educated in all facets of design and
                      construction. They are the middleman between your wants
                      and needs and the actual construction at your building
                      site. They are the communicators in the language of
                      construction.
                    </p>
                    <p>
                      Prior to actual construction, architects see and
                      coordinate the big picture when it comes to your project.
                      We help you explore what appeals to you aesthetically and
                      what you require functionally. We coordinate design,
                      engineering and construction professionals to focus on
                      your specific project; we sort through the maze of
                      building codes, energy, fire, plumbing, and electrical
                      regulations and planning and zoning requirements; we
                      provide design leadership so that your project is built
                      the way you want it – comfortable, attractive, impressive,
                      functional and, of course, efficient.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    At what point in my project should I involve an architect?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>
                      As soon as you decide you want to begin planning your
                      project, you should lean heavily on the advice of a
                      professional who has previous experience in your project
                      type and who can advise on a wide range of important
                      components before critical decisions are made. Architects
                      provide important pre-design services including site
                      evaluation, and can help you explore options you may not
                      have considered. We are customarily hired as partners in
                      the site selection and are advisors in the
                      investment/expense decisions so critical to your project
                      success. Involving an architect early in the process can
                      help avoid costly missteps, and greatly increase the
                      likelihood of your improved lifestyle and satisfaction
                      with the project.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Why should Jeff Abrams be a leading candidate for my
                    project.
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <p>
                      With many years of experience in the residential realm, we
                      have worked in most municipalities up and down the front
                      range, the mountains and outside the state of Colorado.
                      Our years of experience designing custom luxury homes,
                      additions, renovations, and remodels, as well as
                      multi-family projects and builder products gives us
                      experience with many client types and the breadth of
                      design knowledge to understand and interpret your
                      particular needs. We are familiar with the products and
                      materials constantly coming on line and have pioneering
                      involvement with the passive solar homes movement. We keep
                      up with the building codes as much as possible and know
                      how to avoid violations. We are constantly attending
                      seminars, conferences and continuing education programs in
                      our field.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Do architects add cost to a project?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p>
                      Hiring an architect can actually save you money in many
                      ways. While it’s true that architects’ fees may seem like
                      an additional project cost, your architect will work
                      alongside you, as a partner, monitoring your budget and
                      negotiating to get the best materials and workmanship at a
                      good price. We do get discounts, and work hand-in-hand
                      with contractors, builders, interior designers and
                      landscape experts.
                    </p>
                    <p>
                      An architect’s design can reduce energy and maintenance
                      costs, and provide an efficient layout so that you don’t
                      overbuild what you really need. The clever architect can
                      find ways to insure that your building works for you and
                      provides energy and cost efficiency. We are often called
                      upon to creatively turn a difficult lot into a successful
                      building site. As Architects spend time planning and fully
                      developing your ideas to avoid changes once construction
                      is underway.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    How do architects get paid?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <p>
                      It is imperative that the parties are able to define a
                      realistic scope of work to reach a fair and equitable
                      payment plan; yet, often, a project may need to start off
                      on an hourly basis until planning, zoning, and design
                      parameters are established.
                    </p>
                    <p>
                      Hourly, stipulated sum and square footage compensation are
                      most common for residential projects once the scope is
                      nailed down.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    What‘s the owner’s role in the design process?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <p>
                      Your architect will depend on you to communicate about
                      your design preferences, functional requirements, and
                      budget.
                    </p>
                    <p>
                      To begin the process, we will require several documents to
                      define existing conditions of the site and/or existing
                      improvements and to assist with creating a scope
                      definition. These may include a Lot survey with
                      topographical information, an Improvement Location
                      Certificate, a Deed, a hydrological report and
                      geotechnical information, and a “program” of your very
                      specific wants and needs. The permitting authority may
                      also require further information.
                    </p>
                    <p>
                      Your timely response to questions and design submissions
                      will help keep the project on track. It is also important
                      for you to raise any concerns you have as the project
                      proceeds, so they can be addressed in the earliest stages.
                    </p>
                    <p>
                      Working in close partnership with your architect, will
                      help achieve a successful outcome for your project.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
